import React from "react";

import Layout from "../components/layout.js";
import { Link } from "gatsby";

import "./404.scss";

export default () => (
  <Layout>
    <div id="section-404" className="content">
      <h2>Whoops - can't find that page right now!</h2>

      <p>
        <Link to="/">Head back home.</Link>
      </p>
    </div>
  </Layout>
);
